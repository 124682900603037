@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

*::selection{
    @apply bg-[#323232] text-white
}


.swiper-pagination-fraction span {
    @apply text-main-gray font-main-font font-bold px-0 
}

.swiper{
    @apply !relative !py-5  
}

.swiper-pagination-fraction {
    @apply !absolute !top-0 !right-0 !w-full !h-fit
}

.swiper-wrapper{
    @apply !relative   
}
.swiper-button-next{
    @apply !text-main-gray !absolute 
}
.swiper-button-prev{
    @apply !text-main-gray !absolute 
}

#porc span{
    @apply !relative
}

.n-transition {
    opacity: 1;
}